import React from 'react';
import General from './General'
const Generalmain = () => {
    return (
        <div className='admin'>
            <General />

        </div>
    );
}

export default Generalmain;
